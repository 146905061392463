import { createContext, useState } from 'react';

const EditorContext = createContext(null);

// initializing editor state
const editorStateInit = {
    entry: {
        type: 'idiom',
        status: 'editing',
    },
    unauthorized: false,
};

// Establishes EditorContext via a custom use-hook.
//  returns as the context value and object containing the selection state and several useful updater functions
export function useEditorContext() {
    const [editorState, setEditorState] = useState(editorStateInit);

    const updateEditorState = (update) => {
        setEditorState(prev => ({ ...prev, ...update }));
    };

    // EditorContext state value surfaces both state data and handy context functions
    return {
        ...editorState,
        update: updateEditorState,
        clear: () => setEditorState(editorStateInit),
    };
}

export default EditorContext;