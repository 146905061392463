import axios from 'axios';

export const defaultOptions = {
    method: 'POST',
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
    },
};

const httpAPI = function (host, path, callOptions) {
    const options = { ...defaultOptions, ...callOptions };
    return new Promise((resolve, reject) => {
        axios(host + path, options)
            .then(response => {
                if (response.data.success) { // front-end-server
                    resolve(response.data);
                } else if (response.data.result === 'OK') { // nlp backend
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => reject(error));
    });
};

export default httpAPI;