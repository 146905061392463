import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as WikinaeLogo } from 'assets/images/wikinae-logo.svg';
import { useCookies } from 'react-cookie';

const AppBar = styled.div`
    position: sticky;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 36px;
    margin-bottom: 20px;
    border-bottom: thin solid lightgray;
    z-index: 100;
    background-color: white;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.08),0px 4px 5px 0px rgba(0,0,0,0.10);
`;

const Title = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: #267e7d;
    width: 100%;
    text-align: center;
`;

const Login = styled.a`
    justify-self: end;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: none;
    min-width: fit-content;
  
    span {
        font-size: 11px;
        margin-right: 8px;
    }
`;

const HomeAppBar = () => {
    const [cookies] = useCookies();
    const loginApp = window.location.hostname === 'localhost'
        ? 'http://localhost:3000/#/login'
        : (window.location.hostname.startsWith('alpha')
            ? 'https://alpha.mirinae.io/#/login'
            : 'https://mirinae.io/#/login')
    return (
        <>
            <AppBar>
                <WikinaeLogo style={{ transform: 'scale(0.7)' }} />
                <Title>Wikinae Editor v0.2</Title>
                <Login href={loginApp} ><span>{cookies.name}</span>Login</Login>
            </AppBar>
            {/*<AppBarMenu />*/}
        </>
    );
};

export default HomeAppBar;
