export const pagePaths = {
    home: '/',
};

export const apiPaths = {
    saveEntry: '/api/wikinae/entry/save',
    getEntry: '/api/wikinae/entry/get',
    listEntries: '/api/wikinae/entry/list',
    categories: '/api/wikinae/categories',
    editLock: '/api/wikinae/editlock',
    deleteEntry: '/api/wikinae/entry/delete',
    imageUpload: '/api/wikinae/image/upload',
    updateSentenceTranslation: '/api/nlp/updatesentencetranslation',
};