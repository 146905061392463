import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import EditorContext from 'modules/contexts/editor';
import EntryForm from 'components/EntryForm';
import EntryList from 'components/EntryList';

import { apiPaths } from 'modules/defines/paths';
import httpAPI from 'modules/apis/http';

const EditorWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const TestIFrame = styled.iframe`
    position: absolute;
    top: 200px;
    left: 200px;
    width: 800px;
    height: 400px;
    border: thin solid red;
`;

const Message = styled.div`
    position: absolute;
    padding: 4px 24px;
    color: red;
   // border: thin solid red;
    z-index: 100;
    text-align: center;
    width: 500px;
    left: 20px; // calc(50% - 200px);
    bottom: 12px;
`;

// const mongoose = require('mongoose');
// const { Schema } = mongoose;
// const { Types: { ObjectId, Array } } = require('mongoose');
// const { Schema: { Types }} = require('mongoose');
//
// // an entry in the dictionary; we will use taggings to encode relationships??  maybe q-tags
// const DictionaryEntry = new Schema({
//        type:         { type: String, index: true, default: 'idiom' }, // type of entry, 'idiom', 'noelogism', 'proverb'
//        user:         { type: ObjectId, ref: "User", required: true, index: true }, // ObjectID of posting user
//        status:       { type: String, index: true },                     // status - 'published', 'editing', 'deleted', 'blocked'
//        entry:        { type: String, index: true },                     // entry in Korean
//        translation:  { type: Object },                                  // short, direct translation, for all translated languages, keyed by lanugage code
//        description:  { type: Object },                                  // full description (perhaps HTML or markdown)
//        exampleUses:  { type: Object },
//        labels:       { type: [String] },
//        tags:         { type: String },                                  // entry trags, spaced-sep'd (stored here for now, to be loaded into Mirinae tagging system when going live)
//        relatedWords: { type: [Object] },                                // during initial wikinae-editor entry, these will be just the strings, eventually contain references to other entries
//        badges:       { type: [Object] },                                // during initial wikinae-editor entry, these will be just the strings, eventually contain references to badge objects
//        eraCreated:   { type: String, default: 'current' },              // have to devise era titles
//        userAgeGroup: { type: String },                                  // user age group DEPRECATED, replace by userAgeGroups
//        userAgeGroups: { type: [String], default: ['17-25'] },           // list of user age groups
//        profanity:    { type: String, default: 'mild' },                 // have to devise profanity levels (eg 'none', 'very mild', 'mild', 'moderate', 'strong', and 'very strong' from the BBC)
//        usability:    { type: String, default: '50%' },                  // have to devise usability levels  DEPRECATED, no longer used
//        duration:     { type: Number, default: 1},                       // 1 to 5, multiples of 5 years, an indication of the term's usability
//        yearsUsed:    { type: String, },                                 // approximate & loose, maybe a year, or decade or range, an idea of the term's historical context
//        origin:       { type: String, },                                 // origin for the entry, such as the Internet, TV, Twitter, fan cafe, etc
//        ranking:      { type: Number, index: true, default: 0 },         // ranking (by upVotes - downVotes) zeitgeist rating
//        upVotes:      { type: Number, default: 0 },                      //
//        downVotes:    { type: Number, default: 0 },                      //
//        editorDiscussion: { type: String, },                             // discussion text between creators & reviewers
//        created:      { type: Date, default: Date.now },                 // date post was created
//        updated:      { type: Date, index: true },                       // date of last edit or update, if we allow editing
// },
//     { versionKey: false }
// );
//
// module.exports = mongoose.model('DictionaryEntry', DictionaryEntry, 'dictionaryEntry');

const options = {
    types: ['Idiom', 'Neologism', 'Proverb'],
    statuses: ['Editing', 'Proofreading', 'Needs clarification', 'Parse-tree issue', 'Final review', 'Published', 'Blocked', 'Deleted'],
    profanityLevels: ['None', 'Mild', 'Moderate', 'Extreme'],
    ageGroups: ['<16', '17-25', '26-39', '40>'],
    usability: ['10%', '25%', '50%', '75%', '100%'],
    duration: [1, 2, 3, 4, 5],
    labels: ['Add label...', 'Animal Friendly','Eco Friendly','Political','LGBTQ', 'Discriminatory', 'Hate Speech', 'Degrading','Explicit',],
    categories: ['Add category...', 'K-pop', 'K-drama', 'Daily life', 'Food and Drink', 'Cosmetics', 'Gaming', 'Alcohol', 'Social media', 'Stocks', 'Animals', 'Sports', 'Politics', '... new ...'],
};

const defaultEntry = {
    type: 'Neologism',
    status: 'Editing',
    entry: '',
    translation: { 'en': '' },
    pronunciation: '',
    description: '',
    userAgeGroup: '17-25', // DEPRECATED, replaced by userAgeGroups to allow more than one group
    userAgeGroups: ['17-25'],
    profanity: 'None',
    usability: '50%',  // DEPRECATED, replaced by duration
    duration: 1,
    yearsUsed: '',
    origin: '',
    categories: [],
    neologism: { derivation: '' },
    idiom: { literalMeaning: '', romanization: '' },
    proverb: { literalMeaning: '', romanization: '' },
    exampleUses: { 'en': [{ ko: '', translation: ''}] },
    labels: [],
    tags: '',
    relatedWords: [],
    badges: [],
    imageURL: null,
    editorDiscussion: '',
    user: '62ba6647310fbc07d3c27fd0',
};

const Editor = () => {
    const editor = useContext(EditorContext);
    const [entry, setEntry] = useState(_.cloneDeep(defaultEntry));
    const [dirty, setDirty] = useState(false);
    const [message, setMessage] = useState('');
    const [reloadEntryList, setReloadEntryList] = useState(1);
    const [type, setType] = useState('Neologism');

    useEffect(() => {
        httpAPI('', apiPaths.categories, { method: 'GET' })
            .then(response => {
                const { data: { categories } } = response;
                categories.forEach(cat => {
                    if (!options.categories.find(c => c === cat))
                        options.categories.splice(options.categories.length - 1, 0, cat);
                })
            })
    }, []);

    const updateEntry = (field, value) => {
        // console.log('updateEntry', field, value);
        setEntry(prev => {
            prev[field] = value;
            return { ...prev };
        });
        setDirty(true);
    };

    const newEntry = async () => {
        console.log('newEntry')
        if (entry._id) {
            // was editing, release lock
            await httpAPI('', apiPaths.editLock, { data: { entryID: entry._id, action: 'release' } });
            setReloadEntryList(reloadEntryList + 1);
        }
        setEntry(_.cloneDeep(defaultEntry));
    };

    const saveEntry = (withStatus) => {
        setMessage('Saving...');
        if (withStatus)
           entry.status = withStatus;
        httpAPI('', apiPaths.saveEntry, { data: { entry } })
            .then(async response => {
                const { newEntry } = response.data;
                if (newEntry)
                    setEntry(prev => ({ ...prev, _id: newEntry._id }));
                else
                    // release edit lock
                    await httpAPI('', apiPaths.editLock, { data: { entryID: entry._id, action: 'release' } });
                setMessage('  ... saved');
                setTimeout(() => setMessage(''), 1500);
                setEntry(_.cloneDeep(defaultEntry));
                setReloadEntryList(reloadEntryList + 1);
                // update selected sentence translation if any
                let selectedEx;
                entry.exampleUses.en.find(ex => {
                    if (Array.isArray(ex)) {
                        ex.forEach(d => {
                            if (d.selectForAnalysis)
                                selectedEx = d;
                        })
                    } else if (ex.selectForAnalysis)
                        selectedEx = ex;
                });
                if (selectedEx) {
                     httpAPI('', apiPaths.updateSentenceTranslation, { data: { sentenceText: selectedEx.ko, language: 'en', translation: selectedEx.translation } });
                }
            })
            .catch((e) => {
                setMessage('** failed to save ***', e);
                setTimeout(() => setMessage(''), 3000);
            });
        setDirty(false);
    };

    const loadEntry = async (en) => {
        if (en?.editing && en._id === entry._id) {
            // current was already loaded & locked, unlock & clear entry form, don't load anything new
            await httpAPI('', apiPaths.editLock, { data: { entryID: en._id, action: 'release' } });
            setEntry(_.cloneDeep(defaultEntry));
            setReloadEntryList(reloadEntryList + 1);
            return;
        }

        try {
            // take edit lock
            await httpAPI('', apiPaths.editLock, { data: { entryID: en._id, action: 'acquire' } });
        } catch (e) {
            const { lock } = e.data;
            setMessage(`This entry is being edited by ${lock.editor.name}`);
            setTimeout(() => setMessage(''), 3000);
            return;
        }

        if (entry._id) {
            // a different entry was loaded for editing, release its lock
            await httpAPI('', apiPaths.editLock, { data: { entryID: entry._id, action: 'release' } });
            setEntry(_.cloneDeep(defaultEntry));
        }

        httpAPI('', `${apiPaths.getEntry}/${en._id}`, { method: 'GET' })
            .then(async response => {
                const { entry } = response.data;
                // schema updates
                entry.labels = entry.labels || [];
                entry.tags = entry.tags || '';
                entry.userAgeGroups = entry.userAgeGroups || [entry.userAgeGroup];
                entry.yearsUsed = entry.yearsUsed || '';
                entry.neologism = entry.neologism || { derivation: '', romanization: '' };
                entry.idiom = entry.idiom || { literalMeaning: '', romanization: '' };
                entry.proverb = entry.proverb || { literalMeaning: '', romanization: '' };
                entry.origin = entry.origin || '';
                entry.pronunciation = entry.pronunciation || '';
                entry.editorDiscussion = entry.editorDiscussion || '';
                entry.relatedWords = typeof entry.relatedWords === 'string'
                    ? [{ ko: entry.relatedWords, entry: undefined }]
                    : (Array.isArray(entry.relatedWords) && entry.relatedWords.length > 0
                        ? (typeof entry.relatedWords[0].text === 'string' ? [{ ko: entry.relatedWords[0].text, entry: undefined }] : entry.relatedWords)
                        : [{ ko: '', entry: undefined }]);
                setEntry(entry);
            })
            .catch((e) => {
                setMessage('** failed to get entry ***', e);
                setTimeout(() => setMessage(''), 3000);
            });
        setReloadEntryList(reloadEntryList + 1);
        setDirty(false);
    };

    return (
        <EditorWrapper>
            {/* <TestIFTestIFramerame src="http://localhost:3000/#/embed?text=나는 한국어를 할 수 있습니다.&explore=1" /> */}
            { editor.unauthorized ? (
                <h2>Login with Admin or Editor access rights</h2>
            ) : (
                <>
                    <EntryForm
                        entry={entry} updateEntry={updateEntry} newEntry={newEntry} saveEntry={saveEntry}
                        setMessage={setMessage} options={options} setType={setType} dirty={dirty}
                    >
                        { message && <Message>{message}</Message> }
                    </EntryForm>
                    <EntryList
                        setEntry={setEntry} loadEntry={loadEntry} defaultEntry={defaultEntry} setMessage={setMessage} reload={reloadEntryList}
                        type={type} filters={['Editing', 'Proofreading', 'Needs clarification', 'Parse-tree issue', 'Final review', 'Published']} />
                </>
            )}
        </EditorWrapper>
    );
};

export default Editor;
