import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from 'pages/Home';
import { pagePaths } from 'modules/defines/paths';

const routes = () => {

    return (
        <Routes>
            <Route path={pagePaths.home} element={<Home />} />
            {/* rather than a 404, show home-page??  */}
            <Route element={<Home />} />
        </Routes>
    );
};

export default routes;
