import React, { useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';

import styled from 'styled-components/macro';

import GlobalContext, { useGlobalContext } from 'modules/contexts/global';
import EditorContext, { useEditorContext } from 'modules/contexts/editor';

const PageLayout = styled.div`
`;

const WikinaeEditor = () => {
    const globalContext = useGlobalContext();
    const editorContext = useEditorContext();

    return (
        <BrowserRouter>
            <GlobalContext.Provider value={globalContext}>
                <EditorContext.Provider value={editorContext}>
                    <PageLayout>
                        <Routes />
                    </PageLayout>
                </EditorContext.Provider>
            </GlobalContext.Provider>
        </BrowserRouter>
    );
};

export default WikinaeEditor;
