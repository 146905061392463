import React from 'react';
import ReactDOM from 'react-dom/client';
import WikinaeEditor from './WikinaeEditor';

import 'index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <WikinaeEditor/>
    </React.StrictMode>
);
