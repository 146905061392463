import React from 'react';
import styled from 'styled-components/macro';

import Editor from 'components/Editor';

const EditorContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 36px;
`;

const EditorContainer = () => {

    return (
        <EditorContainerWrapper>
            <Editor />
        </EditorContainerWrapper>
    );
};

export default EditorContainer;
