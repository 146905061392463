import React from 'react';
import styled from 'styled-components/macro';

const FooterWrapper = styled.div`
`;

const Footer = () => {

    return (
        <FooterWrapper>
        </FooterWrapper>
    );
};

export default Footer;
