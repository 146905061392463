import React from 'react';
import styled from 'styled-components/macro';

import HomeAppBar from 'components/HomeAppBar';
import EditorContainer from 'containers/EditorContainer';
import Footer from 'components/Footer';

const HomeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat, Roboto, Helvetica, Arial, sans-serif;
`;

const AppBody = styled.div`
    display: flex;
    flex-display: row;
`;

const Home = () => {

    return (
        <HomeWrapper>
            <HomeAppBar />
            <AppBody>
                <EditorContainer />
            </AppBody>
            <Footer />
        </HomeWrapper>
    );
};

export default Home;
