import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import ContentEditable from 'react-contenteditable';
import TextareaAutosize from 'react-textarea-autosize';
import FormData from 'form-data';

import EditorContext from 'modules/contexts/editor';
import UI from 'components/UI';

import { ReactComponent as AddFABIcon } from 'assets/images/plus-btn.svg';
import { ReactComponent as AddExIcon } from 'assets/images/add-example.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { ReactComponent as Face1 } from 'assets/images/face1.svg';
import { ReactComponent as Face2 } from 'assets/images/face2.svg';
import { ReactComponent as ExploreIcon } from 'assets/images/explore.svg';

import httpAPI from 'modules/apis/http';
import { apiPaths } from 'modules/defines/paths';

const Form = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 800px;
    align-items: center;
    // border: thin solid lightgray;
    border-radius: 2px;
    padding: 0px 65px 45px 65px;
    // box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
`;

const Title = styled.h3`
    
`;

const AddNewEntryButton = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    filter: drop-shadow(1px 3px 3px rgba(0,0,0,0.25));
    &:hover {
      filter: drop-shadow(1px 3px 6px rgba(0,0,0,0.25));
    }
    cursor: pointer;
`;

const AddExampleButton = styled.div`
    stroke: darkgray;
    fill: darkgray;
    transform: scale(0.4);
    position: absolute;
    bottom: -14px;
    right: -11px;
    cursor: pointer;
  
    &:hover {
        fill: #00c7db;
    }
`;

const DeleteButton = styled.div`
    stroke: darkgray;
    fill: darkgray;
    transform: scale(0.35);
    position: absolute;
    bottom: -14px;
    right: -16px;
    cursor: pointer;
  
    &:hover {
        fill: #00c7db;
    }
`;


const ActionButton = styled.button`
    position: absolute;
    right: 25px;
    width: 85px;
    font-size: 14px;
    font-weight: 500;
    border: thin solid lightgray;
    background-color: white;
    border-radius: 8px;
    padding: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
`;

const SaveButton = styled(ActionButton)`
    bottom: 12px;
    color: ${props => (props.disabled ? 'grey' : '#ba0101')};
    width: fit-content;
    right: ${props => props.right}px;
    &:hover {
        ${props => !props.disabled && (`
            background-color: #da5d5d;
            color: white;
        `)};
    }
`;

const NewButton = styled(ActionButton)`
    top: 11px;
    color: ${props => (props.disabled ? 'grey' : 'green')};
  
    &:hover {
        ${props => !props.disabled && (`
            background-color:green;
            color: white;
        `)};
    }
`;

const TextInput = styled(ContentEditable)`
  position: relative;
  width: calc(100% - 38px);
  font-size: 12px;
  padding: 12px;
  //white-space: nowrap;
  border: thin solid lightgray;
  border-radius: 5px;

  & + & {
    margin-top: 12px;
  }

  &:focus {
    // background: #f2f7ff;
    outline-width: 1px;
    outline-color: lightblue;
  }

  &:before {
    position: absolute;
    left: 15px;
    top: -8px;
    font-size: 11px;
    background-color: white;
    padding: 0 4px;
  }
`;

const EditorDiscussionBox = styled.div`
  position: relative;
  width: calc(100% - 38px);
  font-size: 12px;
  padding: 12px;
  //white-space: nowrap;
  border: thin solid lightgray;
  border-radius: 5px;

  & + & {
    margin-top: 12px;
  }

  &:focus {
    // background: #f2f7ff;
    outline-width: 1px;
    outline-color: lightblue;
  }

  &:before {
    position: absolute;
    left: 15px;
    top: -8px;
    font-size: 11px;
    background-color: white;
    padding: 0 4px;
    content: 'Editor discussion';
  }
`;

const EditorDiscussion = styled(TextareaAutosize)`
    min-height: 3.25em;
    font-size: 14px; 
    overflow: hidden;
    display: block;
    width: 100%;
    border: none;
    font-family: Montserrat;
`;

const MainEntry = styled(TextInput)`
    margin-top: 12px;
    text-align: center;
    font-size: 32px;
    font-family: 'Noto Sans KR';
  
    &:before {
        content: 'Main Entry';
    }
`;

const Translation = styled(TextInput)`
    font-size: 22px;
    text-align: center;
  
    &:before {
        content: 'Translation';
    }
`;

const Description = styled(TextInput)`
    min-height: 3.25em;
    font-size: 14px; 
    white-space: pre-wrap;
  
    &:before {
        content: 'Description';
    }
`;

const Tags = styled(TextInput)`
    font-size: 14px; 
    margin: 0px 0px;
    margin-bottom: 0px;
    &:before {
        content: 'Category tags (comma-separated)';
    }
`;

const Derivation = styled(TextInput)`
    font-size: 14px; 
    margin: 0px 0px;
    margin-bottom: 12px;
    &:before {
        content: 'Neologism derivation';
    }
`;

const LiteralMeaning = styled(TextInput)`
    font-size: 14px; 
    margin: 0px 0px;
    margin-bottom: 6px;
    &:before {
        content: 'Literal meaning';
    }
`;

const Romanization = styled(TextInput)`
    font-size: 14px; 
    margin: 0px 0px;
    margin-bottom: 12px;
    &:before {
        content: 'Romanization';
    }
`;

const YearsUsed = styled(TextInput)`
    font-size: 14px; 
    margin: 0px 0px;
    margin-top: 12px;
    &:before {
        content: 'Years used';
    }
`;

const Origin = styled(TextInput)`
    font-size: 14px; 
    margin: 0px 0px;
    margin-top: 12px;
    &:before {
        content: 'Origin';
    }
`;

const Pronunciation = styled(TextInput)`
    font-size: 14px; 
    margin: 0px 0px;
    margin-top: 12px;
    &:before {
        content: 'Pronunciation';
    }
`;

const RelatedWords = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% - 38px);
    font-size: 12px;
    padding: 12px 12px 20px 12px;
    //white-space: nowrap;
    border: thin solid lightgray;
    border-radius: 5px;
    margin: 15px;
    // min-height: 40px;
    
    &:before {
        content: 'Related words';
        position: absolute;
        left: 15px;
        top: -8px;
        font-size: 11px;
        background-color: white;
        padding: 0 4px;
    }
`;

const Badges = styled(TextInput)`
    font-size: 14px; 
  
    &:before {
        content: 'Badges';
    }
`;

const Examples = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% - 38px);
    font-size: 12px;
    padding: 12px 12px 20px 12px;
    //white-space: nowrap;
    border: thin solid lightgray;
    border-radius: 5px;
    margin: 15px;
    min-height: 40px;
    
    &:before {
        content: 'Examples';
        position: absolute;
        left: 15px;
        top: -8px;
        font-size: 11px;
        background-color: white;
        padding: 0 4px;
    }
`;

const ExampleColTitles = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 12px;
    //font-weight: 500;
    margin: 0px 40px;
    span {
        width: calc(50% - 20px);
    }
`;

const DialogExample = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0.5px #c2c2c2 solid;
  border-radius: 5px;
  padding: 5px;
  margin: 7px 10px 0 0;
`;

const SentenceExample = styled(DialogExample)`
`;

const Example = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: start;
`;

const KoreanEx = styled(ContentEditable)`
    padding: 4px 0px 4px 4px;
    width: calc(50% - 35px);
    font-family: 'Noto Sans KR';
    font-size: 14px;
    margin: 4px 0px 4px 12px;
    border-bottom: thin solid lightgray;
    &:focus {
        outline-color: lightblue;
    }
    a {
        position: absolute;
        top: 0px;
        right: 0px;
    }
`;

const TranslatedEx = styled(ContentEditable)`
    padding: 4px;
    font-size: 14px;
    width: calc(50% - 35px);
    margin: 4px 20px 4px 20px;
    border-bottom: thin solid lightgray;
    &:focus {
        outline-color: lightblue;
    }
`;

const ExploreBtn = styled.a`
    transform: scale(0.5) translate(-10px,7px);
    opacity: 0.75;
    cursor: pointer;
`;

const SelectForAnalysis = styled.div`
    font-size: 15px;
    color: ${props => (props.checked ? '#01c658' : '#d3d3d3')};
    cursor: pointer;
    margin-top: 11px;
`;

const Faces = styled.div`
    cursor: pointer;
    margin-top: 13px;
`;

const ParseTreeIFrame = styled.iframe`
    width: 100%;
    height: 300px;
    border: none;
    margin: 10px 0;
    pointer-events: none;
`;

const RelatedWordEntry = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;
const RelatedWord = styled(ContentEditable)`
    padding: 1px 0px 1px 4px;
    width: calc(50% - 35px);
    font-family: 'Noto Sans KR';
    font-size: 14px;
    margin: 4px 0px 4px 12px;
    border-bottom: thin solid lightgray;
    &:focus {
        outline-color: lightblue;
    }
    a {
        position: absolute;
        top: 0px;
        right: 0px;
    }
`;


const RelatedsDropdown = styled.div`
    position: absolute;
    top: 32px;
    left: 12px;
`;

const Labels = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    align-self: flex-start;
    align-items: center;
`;

const EditableLabel = styled(ContentEditable)`
    min-width: 70px;
    outline-color: transparent;
    padding: 0 5px;
`;

const Categories = styled(Labels)`
`;

const Label = styled.div`
    display: flex;
    margin: 0px 3px;
    font-size: 12px;
    border: thin solid lightgrey;
    padding: 2px 4px;
    border-radius: 10px;
`;

const LabelDelete = styled.div`
    margin-left: 5px;
    color: grey;
    cursor: pointer;
  
    &:hover {
        color: #ba0101;
    }
`;

const AgeGroups = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    align-items: center;
  
    span {
        font-size: 11px;
        margin: 0 0 0 7px;
    }
`;

const GroupCheckbox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2px 5px;
  
    span {
        font-size: 12px;
        font-weight: 500;
    }
`;

const ID = styled.div`
    position: absolute;
    left: 13px;
    bottom: 3px;
    font-size: 11px;
    color: #5b5b5b;
`;

const ImageUpload = styled.div`
    position: relative;
    width: calc(100% - 38px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: thin solid lightgray;
    border-radius: 5px;
    margin-bottom: 12px;
    
    & + & {
      margin-top: 12px;
    }
    
    &:focus {
        // background: #f2f7ff;
        outline-width: 1px;
        outline-color: lightblue;
    }
    
    &:before {
        position: absolute;
        left: 15px;
        top: -8px;
        font-size: 11px;
        background-color: white;
        padding: 0 4px;
        content: 'Image';
    }
`;

const ImageButtons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    button {
        color: #595959;
        font-size: 11px;
        font-weight: 500;
        border: thin solid lightgray;
        background-color: white;
        border-radius: 8px;
        padding: 4px 6px;
        // margin: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        cursor: pointer;
      
        &:hover {
          background-color: #eeeeee;
        }
    }
    
    button + button {
        margin-top: 10px;
    }
`;

const Image = styled.img`
    margin-left: 10px;
    max-height: 250px;
    object-fit: cover;
`;

const EntryForm = ({ entry, updateEntry, options, newEntry, saveEntry, dirty, setType, children }) => {
    const [search, setSearch] = useState('');
    const [imageURL, setImageURL] = useState(null);
    const [showExampleParseTree, setShowExampleParseTree] = useState(null);
    const [matchingRelateds, setMatchingRelateds] = useState([]);
    const searchTimer = useRef(null);
    const activeRelated = useRef(null);

    useEffect(() => setImageURL(entry.imageURL), [entry.imageURL]);

    const fieldUpdated = (field, value) => {
        // console.log('fieldUpdated', field, value);
        // console.log('    ', entry);
        if (entry[field] !== value) {
            // console.log('    updating', final, value);
            updateEntry(field, value);
        }
    };

    const subFieldUpdated = (e, field, subfield, value) => {
        if (entry[field][subfield] !== value) {
            // console.log('    updating', final, value);
            const sfv = { ...entry[field], [subfield]: value };
            updateEntry(field, sfv);
        }
    };

    const translationUpdate = (e, value) => {
        if (entry.translation.en !== value) {
            const ent = { en: value }
            updateEntry('translation', ent);
        }
    };

    const addNewExample = () => {
        const examples = entry.exampleUses.en;
        examples.push({ ko: '', translation: '' });
        updateEntry('exampleUses', { en: examples } );
    };

    const deleteExample = (i) => {
        const examples = entry.exampleUses.en;
        examples.splice(i, 1);
        updateEntry('exampleUses', { en: examples } );
    };

    const addNewRelatedWord = () => {
        entry.relatedWords.push({ ko: '', entry: null });
        updateEntry('relatedWords', entry.relatedWords );
        setMatchingRelateds([]);
        setSearch('');
    };

    const deleteRelatedWord = (i) => {
        entry.relatedWords.splice(i, 1);
        updateEntry('relatedWords', entry.relatedWords );
        setMatchingRelateds([]);
        setSearch('');
    };

    const exampleUpdated = (e, i, field, value) => {
        if (entry.exampleUses.en[i][field] !== value) {
            // fricking useState update buillshit
            const examples = { ...entry.exampleUses };
            const example = entry.exampleUses.en[i];
            example[field] = value;
            examples.en[i] = example;
            updateEntry('exampleUses', examples);
        }
    };

    const exampleDlgUpdated = (e, i, dlg, field, value) => {
        if (entry.exampleUses.en[i][dlg][field] !== value) {
            const examples = { ...entry.exampleUses };
            const example = entry.exampleUses.en[i][dlg];
            example[field] = value;
            examples.en[i][dlg] = example;
            updateEntry('exampleUses', examples);
        }
    };

    const selectForAnalysis = (i, dlg) => {
        const examples = { ...entry.exampleUses };
        examples.en.forEach((ex, j) => {
            if (Array.isArray(ex)) {
                ex.forEach((d, k) => {
                    d.selectForAnalysis = i === j && dlg === k;
                })
            } else {
                ex.selectForAnalysis =  i === j && dlg === undefined;
            }
        })
        updateEntry('exampleUses', examples);
    };

    const labelAdd = (e, label) => {
        if (!entry.labels.find(l => l === label)) {
            const labels = [...entry.labels];
            labels.push(label);
            updateEntry('labels', labels);
        }
    };

    const deleteLabel = (i) => {
        const labels = [...entry.labels];
        labels.splice(i, 1);
        updateEntry('labels', labels);
     };

    const categoryAdd = (e, cat) => {
        if (!entry.categories.find(l => l === cat)) {
            const categories = [...entry.categories];
            categories.push(cat);
            updateEntry('categories', categories);
        }
    };

    const deleteCategory = (i) => {
        const categories = [...entry.categories];
        categories.splice(i, 1);
        updateEntry('categories', categories);
     };

    const newCategory = (e, i) => {
        const newCat = e.currentTarget.innerText;
        const categories = [...entry.categories];
        categories[i] = newCat;
        updateEntry('categories', categories);
        options.categories.splice(options.categories.length - 1, 0, newCat);
    };

    const newCategoryChanged = (e) => {
        const target = e.currentTarget;
        const text = target.innerText;
        if (text.indexOf('\n') >= 0) {
            target.textContent = text.trim();
            target.blur();
        }
    };

    const phraseList = (field) => field ? field.map(p => p.text).join(', ') : '';

    const phraseListFieldUpdated = (e, field, text) => {
        const phrases = text.split(',').map(p => ({ text: p.trim() }));
        updateEntry(field, phrases);
    };

    const ageGroupChange = (e, group) => {
        if (e.target.checked) {
            if (!entry.userAgeGroups.includes(group)) {
                const groups = options.ageGroups.filter(g => entry.userAgeGroups.includes(g) || g === group);
                updateEntry('userAgeGroups', groups);
            }
        } else {
            const gIndex = entry.userAgeGroups.indexOf(group);
            if (gIndex >= 0) {
                const groups = [...entry.userAgeGroups];
                groups.splice(gIndex, 1);
                updateEntry('userAgeGroups', groups);
            }
        }
    };

    const relatedWordUpdate = (e, i) => {
        const value = e.target.textContent;
        if (entry.relatedWords[i].ko !== value) {
            const relatedWords = [...entry.relatedWords];
            relatedWords[i].ko = value;
            updateEntry('relatedWords', relatedWords);
            setMatchingRelateds([]);
            setSearch('');
        }
   };

    const relatedWordChange = (e, i) => {
        activeRelated.current = i;
        const text = e.currentTarget.textContent;
        entry.relatedWords[i].ko = text;
        const applySearch = () => {
            setSearch(text);
            searchTimer.current = null;
        };
        if (searchTimer) {
            clearTimeout(searchTimer.current);
        }
        searchTimer.current = setTimeout(applySearch, 500);
    }

    useEffect(() => {
        if (search !== '') {
            const params = { data: { search, itemOffset: 0, itemsPerPage: 15 } };
            httpAPI('', apiPaths.listEntries, params)
                .then(response => {
                    const { entries } = response.data;
                    const selectOptions = entries.map(e => ({ label: `${e.entry} - ${e.translation ? e.translation.en : '?'}`, value: e.entry, _id: e._id }));
                    setMatchingRelateds(['Select related...', search].concat(selectOptions));
                })
        }
    }, [search]);

    const selectRelated = (e, i) => {
        const relatedWords = [...entry.relatedWords];
        relatedWords[i] = { ko: e.target.value, entry: matchingRelateds[i]._id };
        updateEntry('relatedWords', relatedWords);
        setMatchingRelateds([]);
        setSearch('');
    };

    const selectImage = (e) => {
        const inputForm = document.getElementById('image-upload-form-id');
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        inputForm.appendChild(input);

        const uploadImage = () => {
            const file = input.files[0];
            let data = new FormData();
            data.append('file', file, entry.entry);

            httpAPI('', apiPaths.imageUpload, {
                data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }}
            ).then((response) => {
                console.log(response);
                const { url } = response.data;
                updateEntry('imageURL', url);
                setImageURL(url);
                //handle success
            }).catch((error) => {
                console.log(error);
            });


        };

        input.addEventListener('change', uploadImage);
        input.click();
    };

    const removeImage = () => {
        updateEntry('imageURL', null);
        setImageURL(null);
    };

    const exploreClicked = (i) => {
        setShowExampleParseTree(showExampleParseTree === i ? null : i);
    };

    const makeSentenceExample = (i) => {
        const examples = { ...entry.exampleUses };
        const example = entry.exampleUses.en[i];
        entry.exampleUses.en[i] = example[0];
        updateEntry('exampleUses', examples);
    };

    const makeDialogExample = (i) => {
        const examples = { ...entry.exampleUses };
        const example = entry.exampleUses.en[i];
        entry.exampleUses.en[i] = [example, { ko: '', translation: ''}];
        updateEntry('exampleUses', examples);
    };

    return (
        <Form>
            <Title>Wikinae Entry</Title>
            <UI.ChoiceButtons label="Type:" selection={entry.type} options={options.types} onChange={choice => { setType(choice); fieldUpdated('type', choice); }} />
            <UI.ChoiceButtons label="Status:" selection={entry.status} options={options.statuses} onChange={choice => fieldUpdated('status', choice)} />
            <MainEntry label="Main entry" html={entry.entry} onBlur={e => fieldUpdated('entry', e.target.textContent)} />
            <Translation html={entry.translation.en} onBlur={e => translationUpdate(e, e.target.textContent)} />
            { entry.type !== 'Idiom' && <Pronunciation html={entry.pronunciation} onBlur={e => fieldUpdated('pronunciation', e.target.textContent)} /> }
            <Description html={entry.description} onBlur={e => fieldUpdated('description', e.target.innerText)} />
            {/*<RelatedWords html={phraseList(entry.relatedWords)} onChange={relatedWordChange} onBlur={e => phraseListFieldUpdated(e, 'relatedWords', e.target.textContent)} />*/}
            <RelatedWords>
                { entry.relatedWords && entry.relatedWords.map((rw, i) => (
                    <RelatedWordEntry key={i}>
                        <RelatedWord html={rw.ko} onChange={e => relatedWordChange(e, i)} onBlur={e => relatedWordUpdate(e, i)} />
                        { activeRelated.current === i && matchingRelateds.length > 0 && search !== '' && (
                            <RelatedsDropdown>
                                <UI.Select style={{ width: 250 }} selection={''} options={matchingRelateds} onChange={e => selectRelated(e, i)} />
                            </RelatedsDropdown>
                        )}
                        <DeleteButton onClick={e => deleteRelatedWord(i)}><DeleteIcon /></DeleteButton>
                    </RelatedWordEntry>
                ))}
                <AddExampleButton onClick={addNewRelatedWord}><AddExIcon /></AddExampleButton>
            </RelatedWords>
            {/* <Badges html={phraseList(entry.badges)} onBlur={e => phraseListFieldUpdated(e, 'badges', e.target.textContent)} /> still in model, hidden for MVP */}
            {/* <Tags html={entry.tags} onBlur={e => fieldUpdated('tags', e.target.textContent)} />  still in model, hidden for MVP */}
            { entry.type === 'Neologism' && <Derivation html={entry.neologism.derivation} onBlur={e => subFieldUpdated(e, 'neologism', 'derivation', e.target.textContent)} /> }
            { entry.type === 'Idiom' && <LiteralMeaning html={entry.idiom.literalMeaning} onBlur={e => subFieldUpdated(e, 'idiom', 'literalMeaning', e.target.textContent)} /> }
            { entry.type === 'Idiom' && <Romanization html={entry.idiom.romanization || ''} onBlur={e => subFieldUpdated(e, 'idiom', 'romanization', e.target.textContent)} /> }
            { entry.type === 'Proverb' && <LiteralMeaning html={entry.proverb.literalMeaning} onBlur={e => subFieldUpdated(e, 'proverb', 'literalMeaning', e.target.textContent)} /> }
            { entry.type === 'Proverb' && <Romanization html={entry.proverb.romanization || ''} onBlur={e => subFieldUpdated(e, 'proverb', 'romanization', e.target.textContent)} /> }

            { entry.type === 'Neologism' && (
                <>
                    <AgeGroups>
                        <span>User age groups:</span>
                        { options.ageGroups.map(g => (
                            <GroupCheckbox key={g}>
                                <span>{g}</span>
                                <input type="checkbox" checked={entry.userAgeGroups.includes(g)} onChange={e => ageGroupChange(e, g)}/>
                            </GroupCheckbox>
                        ))}
                    </AgeGroups>
                    <Categories>
                        <UI.Select label="Categories:" style={{ marginRight: 10 }} selection="Add category..." options={options.categories} onChange={e => categoryAdd(e, e.target.value)} />
                        { entry.categories.map((cat, i) => (
                            <Label key={i}>
                                { cat === '... new ...' ? <EditableLabel autoFocus html='' onChange={newCategoryChanged} onBlur={e => newCategory(e, i)} /> : <span>{cat}</span> }
                                <LabelDelete onClick={e => deleteCategory(i)}>x</LabelDelete>
                            </Label>
                        ))}
                    </Categories>
                    <Labels>
                        <UI.Select label="Labels:" style={{ marginRight: 10 }} selection="Add label..." options={options.labels} onChange={e => labelAdd(e, e.target.value)} />
                        { entry.labels.map((label, i) => (
                            <Label key={i}><span>{label}</span><LabelDelete onClick={e => deleteLabel(i)}>x</LabelDelete></Label>
                        ))}
                    </Labels>
                    <UI.Select label="Profanity level:" selection={entry.profanity} options={options.profanityLevels} onChange={e => fieldUpdated('profanity', e.target.value)} />
                    {/*<UI.Select label="Usability:" selection={entry.usability} options={options.usability} onChange={e => fieldUpdated('usability', e.target.value)} />*/}
                    <UI.Select label="Duration:" selection={entry.duration} options={options.duration} onChange={e => fieldUpdated('duration', e.target.value)} />
                    <YearsUsed html={entry.yearsUsed} onBlur={e => fieldUpdated('yearsUsed', e.target.textContent)} />
                    <Origin html={entry.origin} onBlur={e => fieldUpdated('origin', e.target.textContent)} />
                </>
            )}

            <Examples>
                <ExampleColTitles><span>Korean</span><span style={{ marginLeft: 40 }}>English</span></ExampleColTitles>
                { entry.exampleUses && entry.exampleUses.en.map((ex, i) => (Array.isArray(ex) ? (
                    <>
                        <DialogExample key={i}>
                            <Example>
                                <Faces onClick={() => makeSentenceExample(i)}><Face1 /></Faces>
                                <KoreanEx html={ex[0].ko} onBlur={e => exampleDlgUpdated(e, i, 0, 'ko', e.target.textContent)} />
                                {/*{ ex.ko && <a style={{ transform: 'scale(0.5) translate(-10px,7px)', opacity: 0.75 }} href={`http://mirinae.io/#/?text=${ex.ko}&explore=1`} target="_blank"><ExploreIcon /></a> }*/}
                                {/*{ ex.ko && <ExploreBtn><ExploreIcon onClick={() => exploreClicked(i)}/></ExploreBtn> }*/}
                                { ex[0].ko && (
                                    <>
                                        <ExploreBtn href={`http://mirinae.io/#/?text=${ex[0].ko}&explore=1`} target="_blank"><ExploreIcon /></ExploreBtn>
                                        <SelectForAnalysis checked={ex[0].selectForAnalysis} onClick={() => selectForAnalysis(i, 0)}>✔</SelectForAnalysis>
                                    </>
                                )}
                                <TranslatedEx html={ex[0].translation} onBlur={e => exampleDlgUpdated(e, i, 0, 'translation', e.target.textContent)} />
                            </Example>
                            <Example>
                                <Faces onClick={() => makeSentenceExample(i)}><Face2 /></Faces>
                                <KoreanEx html={ex[1].ko} onBlur={e => exampleDlgUpdated(e, i, 1, 'ko', e.target.textContent)} />
                                { ex[1].ko && (
                                    <>
                                        <ExploreBtn href={`http://mirinae.io/#/?text=${ex[1].ko}&explore=1`} target="_blank"><ExploreIcon /></ExploreBtn>
                                        <SelectForAnalysis checked={ex[1].selectForAnalysis} onClick={() => selectForAnalysis(i, 1)}>✔</SelectForAnalysis>
                                    </>
                                )}
                                <TranslatedEx html={ex[1].translation} onBlur={e => exampleDlgUpdated(e, i, 1, 'translation', e.target.textContent)} />
                            </Example>
                            <DeleteButton style={{ right: -36, bottom: -12 }} onClick={e => deleteExample(i)}><DeleteIcon /></DeleteButton>
                        </DialogExample>
                        { showExampleParseTree === i && <ParseTreeIFrame src={`http://localhost:3000/#/embed?text=${ex.ko}&explore=1`} /> }
                    </>
                ) : (
                    <SentenceExample key={i}>
                        <Example>
                            <Faces onClick={() => makeDialogExample(i)}><Face1 /></Faces>
                            <KoreanEx html={ex.ko} onBlur={e => exampleUpdated(e, i, 'ko', e.target.textContent)} />
                            { ex.ko && (
                                <>
                                    <ExploreBtn href={`http://mirinae.io/#/?text=${ex.ko}&explore=1`} target="_blank"><ExploreIcon /></ExploreBtn>
                                    <SelectForAnalysis checked={ex.selectForAnalysis} onClick={() => selectForAnalysis(i)}>✔</SelectForAnalysis>
                                </>
                            )}

                            <TranslatedEx html={ex.translation} onBlur={e => exampleUpdated(e, i, 'translation', e.target.textContent)} />
                        </Example>
                        <DeleteButton style={{ right: -36, bottom: -12 }} onClick={e => deleteExample(i)}><DeleteIcon /></DeleteButton>
                        { showExampleParseTree === i && <ParseTreeIFrame src={`http://localhost:3000/#/embed?text=${ex.ko}&explore=1`} /> }
                    </SentenceExample>
                )))}
                <AddExampleButton onClick={addNewExample}><AddExIcon /></AddExampleButton>
            </Examples>
            <ImageUpload>
                <ImageButtons>
                    <button onClick={selectImage}>Select image</button>
                    { imageURL && <button onClick={removeImage}>Remove image</button> }
                </ImageButtons>
                { imageURL && <Image src={imageURL} /> }
            </ImageUpload>

            <EditorDiscussionBox>
                <EditorDiscussion ref={ref => { if (ref) ref.value = entry.editorDiscussion; }} defaultValue={entry.editorDiscussion} spellCheck={false} onBlur={e => fieldUpdated('editorDiscussion', e.target.value)} />
            </EditorDiscussionBox>
            {/* <AddNewEntryButton onClick={addNewEntry}><AddFABIcon /></AddNewEntryButton> */}
            <NewButton disabled={dirty} onClick={newEntry}>New entry</NewButton>
            { entry._id && <ID>id: {entry._id.toString()}</ID> }
            <SaveButton right={224} disabled={entry.entry.length === 0} onClick={e => saveEntry('Needs clarification')}>Save for clarification</SaveButton>
            <SaveButton right={77} disabled={entry.entry.length === 0} onClick={e => saveEntry('Final review')}>Save for final review</SaveButton>
            <SaveButton right={25} disabled={entry.entry.length === 0} onClick={e => saveEntry(null)}>Save</SaveButton>
            {children}
        </Form>
    );
}

export default EntryForm;
