import { createContext, useState } from 'react';

const GlobalContext = createContext(null);

// initializing global state
const globalStateInit = {
};

// Establishes GlobalContext via a custom use-hook.
//  returns as the context value and object containing the selection state and several useful updater functions
export function useGlobalContext() {
    const [globalState, setGlobalState] = useState(globalStateInit);

    const updateGlobalState = (update) => {
        setGlobalState((prev) => ({ ...prev, ...update }));
    };

    // GlobalContext state value surfaces both state data and handy context functions
    return {
        ...globalState,
        update: updateGlobalState,
        clear: () => setGlobalState(globalStateInit),
    };
}

export default GlobalContext;