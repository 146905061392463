import React, { useContext, useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import _ from "lodash";

import ContentEditable from 'react-contenteditable';
import ReactPaginate from 'react-paginate';

import EditorContext from 'modules/contexts/editor';

import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import {ReactComponent as AddExIcon} from "../assets/images/add-example.svg";
import UI from "./UI";

import httpAPI from "modules/apis/http";
import { apiPaths } from "modules/defines/paths";

const EntryListWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 800px;
    align-items: center;
    margin-top: 10px;
    // border: thin solid lightgray;
    // font-family: Helvetica, Arial, sans-serif;
    border-radius: 2px;
    padding: 0px 65px 35px 65px;
    // box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
 `;

const SearchBox = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 24px);
    align-self: flex-start;
    position: relative;
    font-size: 14px;
    padding: 8px 12px;
    //white-space: nowrap;
    border: thin solid lightgray;
    border-radius: 5px;
    margin: 15px 0 10px 0;
    
    &:before {
        content: 'Search';
        position: absolute;
        left: 15px;
        top: -8px;
        font-size: 11px;
        background-color: white;
        padding: 0 4px;
    }
 `;

const SearchInput = styled(ContentEditable)`
    width: 100%;
    &:focus {
        outline: none;
    }
`;

const List = styled.table`
    position: relative;
    border: thin solid lightgray;
    width: 800px;
    border-collapse: collapse;
    font-size: 12px;
    margin-top: 6px;
`;

const Header = styled.thead`
  background-color: #e5e5e5;
`;

const Heading = styled.td`
    text-align: left;
    padding: 3px 0px;
    margin-bottom: 2px;
    font-weight: 600;
`;

const EntryRow = styled.tr`
    position: relative;
    cursor: pointer;
`;

const Entry = styled.td`
    text-align: left;
    vertical-align: top;
    padding: 3px 4px 3px 0px;
    overflow: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
`;

const Editing = styled.td`
    color: red;
`;

const Tools = styled.td`
    position: absolute;
    top: -14px;
    right: -35px;
    display: flex;
    flex-direction: row;
    transform: scale(0.35);
`;

const ConfirmTools = styled.td`
    position: absolute;
    top: 3px;
    right: 8px;
    display: flex;
    flex-direction: row;
    z-index: 105;
`;

const EditTool = styled.div`
    fill: gray;
    margin-left: 14px;
    cursor: pointer;
`;

const ConfirmButton = styled.button`
    font-size: 11px;
    margin-left: 4px;
`;

const Paginator = styled(ReactPaginate)`
    font-size: 12px;
    display: flex;
    flex-direction: row;
    
    li {
        display: inline;
        text-align: center;
        padding: 0 4px;
        cursor: pointer;
    }
  
    li.disabled {
        cursor: inherit;
        color: #a8a8a8;
    }

    li + li {
        margin: 0 4px;
    }
`;

const TotalEntries = styled.tfoot`
    position: absolute;
    bottom: -28px;
    right: 0px;
    
    span { 
        font-weight: 500;
        margin: 0 4px;
    }
`;

const EntryList = ({ setEntry, loadEntry, defaultEntry, type, filters, setMessage, reload }) => {
    const editor = useContext(EditorContext);
    const [entryList, setEntryList] = useState([]);
    const [search, setSearch] = useState('');
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [pageCount, setPageCount] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);

    const [confirmDelete, setConfirmDelete] = useState(-1);
    const [filterOptions, setFilterOptions] = useState(filters);
    const [filter, setFilter] = useState([]);
    const searchTimer = useRef(null);
    const fromItem = itemOffset + 1;
    const toItem = Math.min((itemOffset / itemsPerPage + 1) * itemsPerPage, totalEntries);

    useEffect(() => {
        // console.log('useEffect')
        reloadEntryList();
    }, [search, type, itemOffset, reload, filter]);

    const reloadEntryList = () => {
        const params = { data: { search, type, filter, itemOffset, itemsPerPage } };
        httpAPI('', apiPaths.listEntries, params)
            .then(response => {
                const { entries, totalEntries, statusCounts } = response.data;
                setEntryList(entries);
                setTotalEntries(totalEntries);
                setPageCount(totalEntries / itemsPerPage);
                setFilterOptions(filters.map(s => statusCounts[s] ? `${s} (${statusCounts[s]})` : s))
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                    editor.update({ unauthorized: true });
                }
            })
    };

    const deleteEntry = (id) => {
        setMessage('Deleting...');
        httpAPI('', apiPaths.deleteEntry, { data: { entryID: id } })
            .then(response => {
                setMessage('  ... deleted');
                setTimeout(() => setMessage(''), 1500);
                setEntry(_.cloneDeep(defaultEntry));
                reloadEntryList();
            })
            .catch((e) => {
                setMessage('** failed to delete ***', e);
                setTimeout(() => setMessage(''), 3000);
            });
    };

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage);
        setItemOffset(newOffset);
    };

    const searchChange = (e) => {
        const text = e.currentTarget.textContent;
        const applySearch = () => {
            setItemOffset(0);
            setSearch(text);
            searchTimer.current = null;
        };
        if (searchTimer) {
            clearTimeout(searchTimer.current);
        }
        searchTimer.current = setTimeout(applySearch, 500);
    };

    const confirmDeleteEntry = (en) => {
        deleteEntry(en._id);
        setConfirmDelete(-1);
    };

    const filterClicked = (status) => {
        const update = [...filter];
        const si = filter.indexOf(status);
        if (si >= 0) update.splice(si, 1);
        else update.push(status);
        setFilter(update);
    };

    const localDate = (dateString) => { // mild hack
        const d = new Date(dateString);
        const local = new Date(d.valueOf() - d.getTimezoneOffset() * 60 * 1000).toISOString().replace('T', ' ');
        return local.substring(0, local.length - 8);
    };

    const shortLocalDate = (datestring) => {
        if (datestring) {
            const ld = localDate(datestring);
            return ld.substring(0, ld.length - 6);
        }
        else return '';
    };

    const entryClicked = (e, en) => {
        loadEntry(en);

        e.stopPropagation();
    };

    const editEntryClicked = (e, en) => entryClicked(e, en);  // for now, same as clicking anywhere on entry

    return (
        <EntryListWrapper>
            <SearchBox>
                <SearchInput html={search} onChange={searchChange} />
            </SearchBox>
            <UI.ChoiceButtons label={`${type} filter:`} outlined selection={filter} options={filterOptions} onChange={filterClicked} />
            <List>
                <Header>
                    <tr>
                        <Heading style={{ paddingLeft: 6 }}>Status</Heading>
                        {/*<Heading style={{ textAlign: 'center' }}>Type</Heading>*/}
                        <Heading>Entry</Heading>
                        <Heading>Translation</Heading>
                        <Heading style={{ textAlign: 'center' }}>Last editor</Heading>
                        <Heading>Last edited</Heading>
                    </tr>
                </Header>
                <tbody>
                    { entryList.map((en, i) => (
                        <EntryRow key={i} onClick={e => entryClicked(e, en)}>
                            <Entry style={{ paddingLeft: 6 }}>{en.status}</Entry>
                            {/*<Entry style={{ textAlign: 'center' }}>{en.type}</Entry>*/}
                            <Entry>{en.entry}</Entry>
                            <Entry>{en.translation.en}</Entry>
                            <Entry style={{ textAlign: 'center' }}>{en.editing ? en.editing.editor.name : (en.editor?.editedBy?.name || '?')}</Entry>
                            { en.editing ? <Editing title={localDate(en.updated)}>editing</Editing> : <Entry style={{ paddingRight: 46 }}>{localDate(en.updated)}</Entry> }
                            {/*<Entry style={{ paddingRight: 46 }}>{en.description}</Entry>*/}
                            { confirmDelete === i ? (
                                <ConfirmTools>
                                    <ConfirmButton style={{ color: 'red' }} onClick={() => confirmDeleteEntry(en)}>Confirm delete</ConfirmButton>
                                    <ConfirmButton onClick={() => setConfirmDelete(-1)}>Cancel</ConfirmButton>
                                </ConfirmTools>
                            ) : (
                                <Tools>
                                    <EditTool onClick={e => editEntryClicked(e, en)}><EditIcon /></EditTool>
                                    <EditTool onClick={e => { setConfirmDelete(i); e.stopPropagation(); }}><DeleteIcon /></EditTool>
                                </Tools>
                            )}
                        </EntryRow>
                    ))}
                </tbody>
                <TotalEntries>Entries<span>{fromItem}</span>to<span>{toItem}</span>of<span>{totalEntries}</span></TotalEntries>
            </List>
            { pageCount > 1 && (
                <Paginator
                    breakLabel="..." nextLabel=">>"  previousLabel="<<" renderOnZeroPageCount={null}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5} pageCount={pageCount}
                  />
            )}
        </EntryListWrapper>
    );
};

export default EntryList;
