import React from 'react';
import styled from 'styled-components/macro';

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    align-self: flex-start;
`;
const ListSelect = styled.select`
    font-size: 14px;
    color: #3c3c3b;
    border-color: #a7a7a7;
    background-color: #ffffff;
    width: 120px;
    border-radius: 5px;
    padding: 2px;
`;

const Label = styled.span`
    font-size: 11px;
    margin: 8px;
    min-width: 88px;
`;

const ChoiceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    align-self: flex-start;
    font-size: 12px;
    margin-bottom: 4px;
  
    span {
        font-weight: 600;
        margin-right: 16px;
        min-width: 45px;
    }
`;

const Option = styled.div`
    cursor: pointer;
        padding: 3px 5px;

    ${props => props.selected ? (`
        color: white;
        font-weight: 700;
        padding: 3px 5px;
        background: #969696;
        border-radius: 5px;
    `) : (`
        :hover {   
            background: #e1e1e1;
            border-radius: 5px;
        }
    `)};
  
    ${props => props.outlined && (`
        font-size: 11px;
        border: 0.5px solid lightgray;
        border-radius: 3px;
        padding: 3px;
    `)};
    
    & + & {
        margin-left: 8px;
    }
`;

const Select = ({ label, selection, options, optGroups, onChange, style }) => (
    <SelectWrapper>
        { label && <Label>{label}</Label>}
        <ListSelect value={selection} style={style} onChange={onChange}>
            { options && options.map((o, i) => <option key={i} disabled={o.disabled} value={o.value || o}>{o.label || o}</option>) }
            { optGroups && optGroups.map(og => (
                <optgroup key={og.group} label={og.group}>
                    { og.options.map((o, i) => <option key={i} value={JSON.stringify(o.includes)}>{o.label}</option>) }
                </optgroup>
            ))}
        </ListSelect>
    </SelectWrapper>
);

const ChoiceButtons = ({ label, selection, options, style, outlined, onChange }) => {
    return (
        <ChoiceWrapper>
            { label && <span>{label}</span> }
            { options.map((opt, i) => (
                <Option key={i} outlined={outlined} style={style} selected={Array.isArray(selection) ? selection.includes(opt) : selection === opt} onClick={() => onChange(opt)}>{opt}</Option>
            ))}
        </ChoiceWrapper>
    )
}

export default {
    Select, ChoiceButtons,
};